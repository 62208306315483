// @codekit-prepend "utils.js"

//var _bezier = 'cubic-bezier(.6,0,.35,1)';
var bezier = 'cubic-bezier(.5,0,0,1)';
var transitionSpeed = 1000;
var aosSliderSpeed = 500;


jQuery(function ($) {

	// var locoScroll = new LocomotiveScroll();
	// locoScroll.on('call', (func) => {
	// 	console.log("loco");
	// 	// Using jQuery events
	// 	console.log(func);
	// 	$(document).trigger(func);
	// 	// Or do it your own way 😎
	// });


	/*--------------------------
	 *
	 *  MENU TOGGLE
	 *
	 *--------------------------*/
	$('.header-menu-button').on('click', function () {
		menuToggle();
	});

	function menuToggle() {
		if ($('body').hasClass('menu-active')) {
			menuClose();
		} else {
			menuOpen();
		}
	}

	function menuOpen() {
		$('body').addClass('menu-active menu-transition');
		$('#menu-overlay').fadeIn(function () {
			$('body').removeClass('menu-transition');
		});
	}

	function menuClose() {
		$('body').removeClass('menu-active').addClass('menu-transition');
		$('#menu-overlay').fadeOut(function () {
			$('body').removeClass('menu-transition');
		});
	}


	/*--------------------------
	 *
	 *  AOS
	 *
	 *--------------------------*/
	$(window).load(function () {
		//if ($('.slider-vertical-wrapper').length) {
		AOS.init({
			duration: 1000,
			easing: 'ease-out-cubic',
			once: true
		});
		//}
	});

	//$(window).load(function () {
	if ($('.slider-vertical-wrapper').length) {
		$('.slider-vertical-wrapper').each(function () {
			var wScroll = false;
			var dis = $(this);
			var disArrow = dis.next('.slider-arrow-down');


			dis.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
				var next = dis.find('[data-slick-index="' + nextSlide + '"]');
				if (next.length) {
					setTimeout(function () {
						next.find('[data-aos]').each(function () {
							$(this).addClass('aos-animate');
						});
					}, aosSliderSpeed);
				}
			});
			dis.slick({
					slidesToShow: 1,
					rows: 0,
					infinite: false,
					arrows: false,
					vertical: true,
					verticalSwiping: true,
					cssEase: bezier,
					speed: transitionSpeed,
					waitForAnimate: true
				})
				.on('wheel', function (e) {
					e.preventDefault();
					if (!wScroll) {
						wScroll = true;
						if (e.originalEvent.deltaY < 0) {
							dis.slick('slickPrev');
							console.log('prev');
						} else {
							//$(this).slick('slickPrev');
							dis.slick('slickNext');
							console.log('next');
						}
						setTimeout(function () {
							wScroll = false;
						}, 1500);
					} else {
						console.log("throttle");
					}
				});
			if (disArrow.length) {
				disArrow.on('click', function () {
					dis.slick('slickNext');
				});
			}
		});
	}

	/*--------------------------
	 *
	 *  RANDOM IMAGES SLIDE VERTICAL
	 *
	 *--------------------------*/

	$(window).load(function () {
		$('.slide-background-random').each(function () {
			var $pa = $(this);
			var maxSearchIterations = 10;
			var min_x = 100;
			var max_x = $(window).innerWidth() - ($(window).innerWidth() / 4);
			var min_y = 100;
			var max_y = $(window).innerHeight() - ($(window).innerHeight() / 2.5);
			var filled_areas = [];

			function calc_overlap(a1) {
				var overlap = 0;
				for (i = 0; i < filled_areas.length; i++) {

					var a2 = filled_areas[i];

					// no intersection cases
					if (a1.x + a1.width < a2.x) {
						continue;
					}
					if (a2.x + a2.width < a1.x) {
						continue;
					}
					if (a1.y + a1.height < a2.y) {
						continue;
					}
					if (a2.y + a2.height < a1.y) {
						continue;
					}

					// intersection exists : calculate it !
					var x1 = Math.max(a1.x, a2.x);
					var y1 = Math.max(a1.y, a2.y);
					var x2 = Math.min(a1.x + a1.width, a2.x + a2.width);
					var y2 = Math.min(a1.y + a1.height, a2.y + a2.height);

					var intersection = ((x1 - x2) * (y1 - y2));

					overlap += intersection;

					// console.log("( "+x1+" - "+x2+" ) * ( "+y1+" - "+y2+" ) = " + intersection);
				}

				// console.log("overlap = " + overlap + " on " + filled_areas.length + " filled areas ");
				return overlap;
			}

			function randomize($parent) {

				filled_areas.splice(0, filled_areas.length);

				var index = 0;
				$parent.find('.image').each(function () {
					var rand_x = 0;
					var rand_y = 0;
					var i = 0;
					var smallest_overlap = 9007199254740992;
					var best_choice;
					var area;
					for (i = 0; i < maxSearchIterations; i++) {
						rand_x = Math.round(min_x + ((max_x - min_x) * (Math.random() % 1)));
						rand_y = Math.round(min_y + ((max_y - min_y) * (Math.random() % 1)));
						area = {
							x: rand_x,
							y: rand_y,
							width: $(this).width(),
							height: $(this).height()
						};
						var overlap = calc_overlap(area);
						if (overlap < smallest_overlap) {
							smallest_overlap = overlap;
							best_choice = area;
						}
						if (overlap === 0) {
							break;
						}
					}

					filled_areas.push(best_choice);

					$(this).css({
						position: "absolute",
						"z-index": index++
					});
					$(this).animate({
						left: rand_x,
						top: rand_y
					});

					// console.log("and the winner is : " + smallest_overlap);
				});
				return false;
			}

			randomize($pa);
		});

	});

	//});



});
